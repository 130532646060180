import { render, staticRenderFns } from "./SpeciesListingComponent.vue?vue&type=template&id=18c3684f&scoped=true&"
import script from "./SpeciesListingComponent.vue?vue&type=script&lang=js&"
export * from "./SpeciesListingComponent.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "18c3684f",
  null
  
)

export default component.exports